.Enter {
  @apply absolute z-10 opacity-0 w-full;

  &.EnterActive {
    @apply opacity-100;
    @apply transition duration-200 ease-in;
  }
}

.Exit {
  @apply absolute z-0 opacity-100 w-full;

  &.ExitActive {
    @apply opacity-0;
    @apply transition duration-200 ease-out;
  }
}
